<template>
  <div>
    <dashboard-page-title
      @addEvent="$router.push({ name: 'addEmployee' })"
      :showAddBtn="$perHelper('users:create')"
      >{{ $t("main.sidebar.employees") }}</dashboard-page-title
    >
    <main-table
      :baseURL="baseURL"
      :fields="fields"
      :hasPagination="false"
      :list_url="'merchant/employees'"
      :statusUrl="'merchant/employees'"
    ></main-table>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
export default {
  components: {},
  mounted() {
    core.index();
  },
  data() {
    return {
      employees: [],
      baseURL: process.env.VUE_APP_API_LINK,
      fields: [
        {
          label: this.$t("main.image"),
          key: "avatar",
          class: "text-start",
          type: "image",
        },
        { label: this.$t("main.name"), key: "name", class: "text-start" },
        { label: this.$t("main.email"), key: "email", class: "text-start" },
        { label: this.$t("main.phone"), key: "phone", class: "text-start" },
        {
          label: this.$t("main.changeStatus"),
          key: "change_status",
        },
        {
          label: this.$t("main.status.status"),
          key: "status",
          type: "status",
          class: "text-start",
        },
        {
          label: this.$t("products.control"),
          key: "actions",
          class: "text-start",
          type: "actions",
          actions: [
            {
              text: "view",
              icon: "ri-eye-fill",
              color: "primary",
              ifNavigate: true,
              routePath: "show-employee/:id",
              showIf: () => this.$perHelper("users:findOne"),
            },
            {
              text: "edit",
              icon: "ri-ball-pen-fill",
              color: "info",
              ifNavigate: true,
              routePath: "edit-employee/:id",
              showIf: () => this.$perHelper("users:update"),
            },
            {
              text: "Delete",
              icon: "ri-delete-bin-line",
              color: "danger",
              url: "merchant/employees",
              titleHeader: this.$t("main.item"),
              question: this.$t("main.deleteConfirmation"),
              textContent: "name",
              textDeleteButton: this.$t("main.delete"),
              textCancelButton: this.$t("main.cancel"),
              showAlert: true,
              // showIf: () => this.$perHelper('users:soft_delete')
            },
          ],
        },
      ],
    };
  },
  methods: {
    changeStatus(data) {
      console.log(data);
    },
  },
};
</script>
